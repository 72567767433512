body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.char-list {
  list-style: none;
  /* max-width: 350px; */
  text-align: center;
  padding: 0 1rem;
}

.char-list li {
  display: inline-block;
  margin: .5rem;
  text-align: center;
}

.char-pad .char-list li {
  margin: 0;
  padding: 0 .5rem;
}

.char-list li button {
  font-size: 1rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: white;
  border: none 0px;
  max-width: 4rem;
  max-height: 4rem;
}

@media screen and (min-width: 768px) {
  .char-list li button {
    max-width: 4.2rem;
    max-height: 4.2rem;
  }
}

@media screen and (min-width: 1024px) {
  .char-list li button {
    max-width: 5rem;
    max-height: 5rem;
  }
}

.char-list li svg {
  max-width: 100%;
  max-height: 100%;
}
.choosen-chars {
  border: 1px solid black;
  position: fixed;
  left: 1rem;
  top: 6rem;
  max-width: 12rem;
}

button {
  cursor: pointer;
}
.game-word {
  list-style: none;
  text-align: center;
  padding: 0;
}
.game-word li {
  display: inline-block;
  margin: 1rem;
  width: 2rem;
  text-align: center;
}
.word-letter {
  border-bottom: 1px solid black;
  height: 2rem;
  position: relative;
}

.word-letter span {
  position: absolute;
  left: 0;
  right: 0;
}

.hidden {
  display: none;
}

.hangmanFigure {
  max-width: 300px;
}

.left {
  float: left;
  width: 50%;
}
.right {
  float: right;
  width: 50%;
}

.man {
  position: fixed;
  max-width: 90vw;
  max-height: 90vh;
  width: 100%;
  height: 100%;
}

.char-pad {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
}

.modal {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  background: white;
  margin: 0 auto;
  padding: 2rem 3rem 4rem;
  width: 300px;
  box-shadow: 10px;
  transform: translateY(-50%);
}

.overlay {
  position: fixed;
  background: rgba(0,0,0, .2);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.modal button {
  font-size: 2rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid black;
}